@import "../../scss/constants";

.load-files {
  tbody {
    max-height: 450px;
    .loading {
      background-color: $indicate-by-yellow;
    }
    .loaded {
      background-color: $indicate-by-green;
    }
    .error {
      background-color: $indicate-by-red;
    }
    .dublicate {
      background-color: $indicate-by-orange;
    }
  }
  .select-page {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 30px;
    & > a {
      display: flex;
      align-items: center;
      justify-content: center;

      padding: 0 15px;
      margin-right: 15px;
      text-decoration: none;
      font-weight: 500;
      font-size: 14px;
      color: #616161;
      height: 30px;
      border-radius: 5px;
      background-color: transparent;
      &.active {
        background: $tab-background;
        color: #fff;
      }
    }
  }
}
