$main-color: #6e76ff;
$body-color: #eaeef9;
$logo-color: #95a5a6;
$logo-color-hover: $main-color;

$bars-color-1: #0ad668;
$bars-color-2: $main-color;
$bars-color-3: #ffa1a3;

$indicate-by-yellow: #f4ff94;
$indicate-by-red: #ffbcbc;
$indicate-by-green: #9cf88a;
$indicate-by-orange: #fcc573;
$indicate-by-blue: #5555ff;
$indicate-by-orange: #fd9533;

$tab-background: linear-gradient(270deg, #6e6dd3 0%, #9391ef 100%);
$button-background: linear-gradient(270deg, #6e6dd3 0%, #9391ef 100%);

$renault-background: linear-gradient(270deg, #6e6dd3 0%, #9391ef 100%);
$mitsubishi-background: linear-gradient(270deg, #f9a4a4 0%, #f9c7c7 100%);
$lada-background: linear-gradient(270deg, #ffc95c 0.01%, #ffdc97 100%);
