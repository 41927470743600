@import "./constants";

.button-main {
  @extend .button-lite-disabled;
  background: $button-background;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
}
.button-main-disabled {
  &:disabled {
    background: #c4c4c4;
    color: #696969;
    cursor: not-allowed;
  }
}
.button-lite {
  @extend .button-lite-disabled;
  background: #f7f7f7;
  border-radius: 5px;
  color: #000000;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.button-lite-disabled {
  &:disabled {
    background: #e5e5e5;
    color: #959595;
    cursor: not-allowed;
  }
}
.tab-main {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  padding: 0 20px;
  margin-right: 5px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  color: #616161;
  font-weight: 500;
  &[data-active="true"],
  &.active {
    background: $tab-background;
    color: white;
    font-weight: 500;
  }
}

.button-link {
  background: transparent;
  color: #666666;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: underline;
  &:disabled {
    background: transparent;
    cursor: not-allowed;
  }
}
