// fonts
$font-size-tier-0: 48px;
$font-size-tier-1: 36px;
$font-size-tier-2: 24px;
$font-size-tier-3: 18px;
$font-size-tier-4: 14px;
$font-size-tier-5: 12px;
$font-size-tier-6: 10px;

// sizes
$controll-element-size: 40px;

// indentation
$indentation-tier-1: 40px;
$indentation-tier-2: 25px;
$indentation-tier-3: 20px;
$indentation-tier-4: 15px;
$indentation-tier-5: 10px;
$indentation-tier-6: 5px;

// colors
$main-color: #ffcc00;
$indicate-by-yellow: #f4ff94;
$indicate-by-red: #ffbcbc;
$indicate-by-green: #9cf88a;
$indicate-by-orange: #fcc573;
$indicate-by-blue: #5555ff;
$indicate-by-orange: #fd9533;
$very-lite-gray: #efefef;
$lite-gray: rgb(227, 230, 228);
$gray: #bbbbbb;
$disabled-color: #cccccc;
$disabled-color-dark: #888888;
$color-table-sort-arrows: #c80000;

// border
$radius-tier-1: 8px;
$radius-tier-2: 4px;
$radius-tier-3: 2px;

@mixin border($width, $color, $radius) {
  border: $width solid $color;
  border-radius: $radius;
}

@mixin lite-border($width: 1px, $radius: 0) {
  @include border($width, $lite-gray, $radius);
}
@mixin common-border($width: 1px, $radius: 0) {
  @include border($width, $gray, $radius);
}

// shadow
$color-shadow: rgba(0, 0, 0, 0.1);
$color-shadow-dark: rgba(0, 0, 0, 0.3);
$color-shadow-very-dark: rgba(0, 0, 0, 0.5);
$shadow-type-1: 1px 1px 10px $color-shadow;
$shadow-type-2: 0px 2px 5px $color-shadow-dark;
$shadow-type-3: 0 4px 5px $color-shadow-very-dark;
$shadow-type-4: 4px 0px 17px $color-shadow;
$shadow-type-4-reverse-x: -4px 0px 17px $color-shadow;

// animation
$transition-fast: linear 0.1s;
$transition-medium: linear 0.3s;
$transition-slow: linear 0.5s;
$transition-scale: 0.2s transform cubic-bezier(0, 0, 0.2, 1);
$transition-enter-function: cubic-bezier(0.6, 0.04, 0.98, 0.335);
$transition-out-function: cubic-bezier(0.785, 0.135, 0.15, 0.86);

// classes
.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.flex-center-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.transition-enter {
  transition: transform $transition-enter-function;
}

.transition-out {
  transition: transform $transition-out-function;
}

.all-screen {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.size-16-9 {
  width: 100%;
  padding-top: 9 / 16 * 100%;
}

.ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

@mixin circle($size) {
  height: $size;
  width: $size;
  border-radius: 50%;
}
