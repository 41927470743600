@import "./scss/constants";
@import "./scss/elements";

body {
  margin: 0;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $body-color;
  * {
    font-family: Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
      "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
      "Helvetica Neue", sans-serif;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  display: flex;
  min-height: 100vh;
}

ol {
  padding: 0;
}

button {
  @extend .button-main;
  height: 30px;
}

.tabs > .tab {
  @extend .tab-main;
}
